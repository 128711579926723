import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import Images
import bg1 from '../../images/background/line-bg1.png';
import ptImg1 from '../../images/shap/circle-orange.png';
import ptImg2 from '../../images/shap/plus-orange.png';
import ptImg3 from '../../images/shap/circle-dots.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<section id='why-hce' className="section-area section-sp5 work-area" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:" no-repeat", backgroundPosition: "center", backgroundSize: "100%"}}>
					<div className="container-sm">
						<div className="heading-bx text-center">
							<h4 className="title-ext text-secondary">Why HCE?</h4>
							<h6 className="title">Don't settle for mediocre profits. Elevate your revenue and gain profound insights into your healthcare business with HealthClaimExperts.</h6>
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Get started with a complimentary initial practice assessment.</h5>
										<p>Receive a complimentary practice assessment to pinpoint areas for improvement within your practice and optimize performance.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx active">
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Revenue Cycle Specialists</h5>
										<p>Specialized billing, coding, and collection experts optimize your payment cycle with an optimal and defined approach.</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-sm-6 mb-30">
								<div className="work-bx">
									<div className="work-content">
										<h5 className="title text-secondary mb-10">Specialized EHR/EMR Platforms</h5>
										<p>Specialty-specific EHR/EMR software is employed to efficiently capture, manage, and transmit clinical data, ensuring accurate billing and coding services.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<img className="pt-img1 animate1" src={ptImg1} alt=""/>
					<img className="pt-img2 animate2" src={ptImg2} alt=""/>
					<img className="pt-img3 animate3" src={ptImg3} alt=""/>
				</section>
				
			</>
		);
	}
}

export default aboutSection;