import React, { Component } from 'react';

class appointmentForm extends Component {
	render() {
		return (
			<>

				<div className="appointment-form form-wraper">
					<h3 className="title">Request Demo</h3>
					<form action="#">
						<div className="form-group">
							<select className="form-select form-control">
								<option value="Select Service Type">Select Service Type</option>
								<option value="Medical Billing Services">Medical Billing Services</option>
								<option value="Credentialing &amp; Enrollment">Credentialing &amp; Enrollment</option>
								<option value="Medical Coding Services">Medical Coding Services</option>
								<option value="Quality Payment Program">Quality Payment Program</option>
								<option value="Front Office Management">Front Office Management</option>
								<option value="Professional Staffing Solutions">Professional Staffing Solutions</option>
								<option value="Collection Services">Collection Services</option>
								<option value="Practice Assessment and Consulting Services">Practice Assessment and Consulting Services</option>
								<option value="Claim Submission Process">Claim Submission Process</option>
								<option value="Patient Billing Services">Patient Billing Services</option>
								<option value="A/R Follow Up Services">A/R Follow Up Services</option>
								<option value="Revenue Cycle Management">Revenue Cycle Management</option>
								<option value="TMS and Spravato Credentialing">TMS and Spravato Credentialing</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div className="form-group">
							<select className="form-select form-control">
								<option value="Select Healthcare Type">Select Healthcare Type</option>
								<option value="Solo Practice">Solo Practice</option>
								<option value="Private Practice">Private Practice</option>
								<option value="Group Practices">Group Practices</option>
								<option value="Health Maintenance Organization(HMOs)">Health Maintenance Organization(HMOs)</option>
								<option value="Hospital">Hospital</option>
								<option value="Intermediate Care Facility">Intermediate Care Facility</option>
								<option value="Accountable care organization">Accountable care organization</option>
								<option value="Nursing Practice">Nursing Practice</option>
								<option value="Ambulatory Surgical Center">Ambulatory Surgical Center</option>
								<option value="Home Health Agency">Home Health Agency</option>
								<option value="Hospice Care">Hospice Care</option>
								<option value="Diagnostic &amp; Imaging Center">Diagnostic &amp; Imaging Center</option>
								<option value="Assisted Living Facility">Assisted Living Facility</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div className="form-group">
							<input type="text" className="form-control" placeholder="Your Name" />
						</div>
						<div className="form-group">
							<input type="number" className="form-control" placeholder="Phone Number" />
						</div>
						<div className="form-group">
							<input type="email" className="form-control" placeholder="Email" />
						</div>
						<button type="submit" className="btn btn-secondary btn-lg">Request Now</button>
					</form>
				</div>

			</>
		);
	}
}

export default appointmentForm;